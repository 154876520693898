<template>
  <div>
    <el-container>
      <el-header>
        <el-menu mode="horizontal"
        :ellipsis="false">
          <div class="flex-grow" />
          <el-menu-item index="0">
            <router-link to="/" class="no-underline">首页</router-link>
          </el-menu-item>
          <el-menu-item index="1">
            <router-link to="/solution" class="no-underline">解决方案</router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/about" class="no-underline">关于我们</router-link>
          </el-menu-item>
          <div class="flex-grow" />
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <div style="background: #409EFF;">
          <p>Copyright © 2023-2024 . 南京念达信息 版权所有</p>
          <el-link href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
            <el-image :src="require('@/assets/beian.png')" fit="contain" />
            <el-text>苏公网安备32010402001558号</el-text>
          </el-link>
          <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
            <el-text>苏ICP备2023023950号-1</el-text>
          </el-link>
        </div>
      </el-footer>
    </el-container>

  </div>
  <el-backtop :right="100" :bottom="100">
  </el-backtop>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-link {
  margin-right: 8px;
}

.router-link {
  margin-right: 10px;
}

.no-underline {
  text-decoration: none;
  /* 移除下划线 */
}

.flex-grow {
  flex-grow: 1;
}

.el-main {
  padding: 0px !important
}

.el-footer {
  padding: 0px !important
}
</style>
